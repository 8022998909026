import produce from 'immer'
import { getNodeInformation, apply, IS_EXPANDED_KEY } from './utils'

/**
 * @readonly
 * @enum {string}
 */
export const Types = Object.freeze({
  EXPAND: 'expand',
  COLLAPSE: 'collapse'
})

export const collapse = (id) => ({ type: Types.COLLAPSE, id })
export const expand = (id) => ({ type: Types.EXPAND, id })

/**
 * Use a map uniqueId -> item?
 */

/**
 * @typedef State
 * @property {array} list
 * @property {string} nodesKey
 * @property {boolean} propagateCollapseToNodes
 */

/**
 * @typedef Action
 * @property {Types} type
 * @property {string} id
 */

/**
 * useTreeView reducer.
 * @param {State} state
 * @param {Action} action
 */
export const reducer = (state, action) =>
  produce(state, (draft) => {
    const { nodesKey, propagateCollapseToNodes } = state
    const { type, id } = action
    switch (type) {
      case Types.EXPAND:
        apply(draft.list, nodesKey, id, (item) => {
          const { isContainer, isExpanded } = getNodeInformation(item, nodesKey)
          if (isContainer && !isExpanded) {
            item[IS_EXPANDED_KEY] = true
          }
        })
        break

      case Types.COLLAPSE:
        apply(draft.list, nodesKey, id, (item) => {
          const { isContainer, isExpanded, items } = getNodeInformation(
            item,
            nodesKey
          )
          if (isContainer && isExpanded) {
            item[IS_EXPANDED_KEY] = false
            if (propagateCollapseToNodes) {
              collapseContainerNodes(items, nodesKey)
            }
          }
        })
        break

      default:
        throw new Error('Unknown action for useTreeView reducer.')
    }
  })

const collapseContainerNodes = (items, nodesKey) => {
  items.forEach((item) => {
    const { isContainer, items } = getNodeInformation(item, nodesKey)
    if (isContainer) {
      item[IS_EXPANDED_KEY] = false
      collapseContainerNodes(items, nodesKey)
    }
  })
}
