import React from 'react'
import { ListItem, MenuDropdown } from '@toasttab/buffet-pui-dropdowns'
import { UserNameButton } from '@toasttab/buffet-pui-user-name'
import { useBanquetProps } from 'banquet-runtime-modules'

const UserDisplayNameItem = () => {
  const { auth } = useBanquetProps()
  const { displayName } = auth.userInfo

  return (
    <div className='p-3 text-default font-semibold type-large'>
      {displayName}
    </div>
  )
}

export const UserAccountActions = () => {
  const { auth } = useBanquetProps()
  return (
    <MenuDropdown
      placement='bottom'
      containerClassName='whitespace-nowrap'
      renderToggle={(props) => <UserNameButton {...props} />}
      testId='user-account-actions'
    >
      <UserDisplayNameItem />
      <ListItem
        testId='logout'
        onClick={() => auth.logout()}
        label='Log out'
      ></ListItem>
    </MenuDropdown>
  )
}
