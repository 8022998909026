import React from 'react'
import ReactDOM from 'react-dom'
import { banquetSingleSpaReact } from 'banquet-runtime-modules'
import { singleSpaCssLifecycles } from '@toasttab/banquet-single-spa-css'

import { AppSetup } from './components/App'
import './index.css'

const reactLifecycles = banquetSingleSpaReact({
  React,
  ReactDOM,
  singleSpaCssLifecycles,
  cssScope: 'data-ta',
  rootComponent: AppSetup,
  sentry: {
    publicKey: '1692c977908c42aa9075aa4d2ac50a69@o37442',
    projectId: '4504776334704640'
  }
})

export const bootstrap = reactLifecycles.bootstrap
export const mount = reactLifecycles.mount
export const unmount = reactLifecycles.unmount
export const name = 'spa-admin-portal'
