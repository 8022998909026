export const UNIQUE_ID_KEY = '_uniqueId'
export const IS_EXPANDED_KEY = '_isExpanded'

/**
 * Get element by id. Although generated ids look like numbers, they are
 * strings. If you try to pass manually an id like `200` instead of `"200"`, it
 * will not work.
 * @param {array} list The list to look through.
 * @param {string} nodesKey The key containing nested items.
 * @param {string} id The id to look for.
 * @returns {object}
 */
export const get = (list, nodesKey, id) => {
  /**
   * The advantage of for..of compared to regular for is to not have to maintain
   * an index; compared to forEach, it exits the loop at the first return;
   * compared to find, it will return only the found item, not the top level
   * related item.
   */
  for (const item of list) {
    if (item[UNIQUE_ID_KEY] === id) {
      return item
    }
    const { isContainer, items } = getNodeInformation(item, nodesKey)
    if (isContainer) {
      const foundItem = get(items, nodesKey, id)
      if (!!foundItem) {
        return foundItem
      }
    }
  }
  return null
}

/**
 * @callback OperationFn
 * @param {object} item
 * @returns {void}
 */

/**
 * Apply operation to found element by id. Uses get internally.
 * @param {array} list The list to look through.
 * @param {string} nodesKey The key containing nested items.
 * @param {string} id The id to look for.
 * @param {OperationFn} operation Operation to perform on the found item.
 */
export const apply = (list, nodesKey, id, operation) => {
  const foundItem = get(list, nodesKey, id)
  if (!!foundItem) {
    operation(foundItem)
  }
}

/**
 * @typedef GetNodeInformationReturn
 * @property {boolean} isContainer
 * Determines if the current node contains other nodes.
 * @property {boolean} isExpanded
 * Determines whether the current node is expanded or not. Will return false
 * if node is not a container.
 * @property {array} items Nodes contained by the current node.
 * @property {string} id Unique id assigned to the current node.
 * @property {number} depth Level of depth of the current node. Starts at 0.
 */

/**
 * Returns relevant information about the current item.
 * @param {object} item The item to analyze.
 * @param {string} nodesKey The key containing nodes for container nodes.
 * @returns {GetNodeInformationReturn}
 */
export const getNodeInformation = (item, nodesKey) => ({
  isContainer: !!item[nodesKey],
  isExpanded: !!item[IS_EXPANDED_KEY],
  items: item[nodesKey],
  id: item[UNIQUE_ID_KEY],
  depth: String(item[UNIQUE_ID_KEY]).length - 1
})
