import React, { createContext, useContext } from 'react'

const initialState = {
  nodesKey: null,
  className: null,
  expand: null,
  collapse: null,
  onClickNode: null
}

export const TreeViewContext = createContext(initialState)

export const TreeViewProvider = ({ children, value }) => (
  <TreeViewContext.Provider value={value}>{children}</TreeViewContext.Provider>
)

/**
 * @typedef UseTreeViewContextReturn
 * @property {string} nodesKey
 * @property {string} className
 * @property {function} expand
 * @property {function} collapse
 * @property {function} onClickNode
 */

/**
 * @returns {UseTreeViewContextReturn}
 */
export const useTreeViewContext = () => {
  const { nodesKey, className, expand, collapse, onClickNode } =
    useContext(TreeViewContext)
  if (!nodesKey && !className && !expand && !collapse && !onClickNode) {
    throw new Error(
      'Illegal use of `TreeViewContext`. `useTreeViewContext` has been called outside of `TreeViewProvider`.'
    )
  }
  return { nodesKey, className, expand, collapse, onClickNode }
}
