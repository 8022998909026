import React from 'react'
import cx from 'classnames'
import { NavLink } from 'react-router-dom'
import { ExpandIcon } from '@toasttab/buffet-pui-icons'
import { slugify } from '../../utils/slugify'

const baseClassNames = `type-default md:type-subhead whitespace-nowrap
inline-flex rounded-md px-4 py-2
outline-none focus-visible:shadow-focus-inset
transition duration-200 ease-in-out`
const baseStyle = { minWidth: '100%' }

export const Node = (props) => {
  const { isExpanded, isContainer, node, toggle, depth } = props
  const { title, link } = node

  if (isContainer) {
    return (
      // Border in outer div as button is rounded
      <div className='mt-1 md:mt-0'>
        <button
          data-testid={slugify(title)}
          onClick={toggle}
          className={cx(
            `flex-row-reverse justify-between items-center
            md:flex-row md:justify-start md:pl-0`,
            'hover:bg-darken-4',
            baseClassNames
          )}
          aria-expanded={isExpanded}
          style={baseStyle}
        >
          <ExpandIcon className='text-secondary' isExpanded={isExpanded} />
          {title}
        </button>
      </div>
    )
  }

  return (
    <NavLink
      data-testid={slugify(title)}
      to={link}
      className={({ isActive }) =>
        cx(baseClassNames, 'mt-1 md:mt-0', {
          /**
           * Will only occur on links within the first depth for desktop
           * (e.g. Restaurant Access)
           */
          'md:px-6': depth === 0,
          'hover:bg-darken-4': !isActive,
          'bg-brand-0 hover:bg-brand-10 active:bg-brand-10 text-brand-75 font-semibold':
            isActive
        })
      }
      style={() => baseStyle}
    >
      {title}
    </NavLink>
  )
}
