import * as React from 'react'
import { useLocation } from 'react-router-dom'
import { Header } from '../Header'
import { Content } from '../Content'
import { Navigation, useNavigationVisibilityContext } from '../Navigation'

export function App() {
  const location = useLocation()
  const [isVisible, toggleVisibility] = useNavigationVisibilityContext()

  React.useEffect(() => {
    isVisible && toggleVisibility()
    // Call only when pathname changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  return (
    <div
      className='h-screen grid bg-gray-0'
      style={{
        gridTemplateColumns: 'minmax(min-content, max-content)',
        gridTemplateRows: 'minmax(min-content, max-content)'
      }}
    >
      <Header />
      <Navigation />
      <Content />
    </div>
  )
}
