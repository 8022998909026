import React, { createContext, useContext } from 'react'

const initialState = [undefined, () => {}]

export const NavigationVisibilityContext = createContext(initialState)

export const NavigationVisibilityProvider = ({ children, value }) => (
  <NavigationVisibilityContext.Provider value={value}>
    {children}
  </NavigationVisibilityContext.Provider>
)

export const useNavigationVisibilityContext = () => {
  const state = useContext(NavigationVisibilityContext)
  const [isVisible, setVisibility] = state
  if (isVisible === undefined) {
    throw new Error(
      'Illegal use of `NavigationVisibilityContext`. `useNavigationVisibilityContext` has been called outside of `NavigationVisibilityProvider`.'
    )
  }
  return [isVisible, () => setVisibility((prevState) => !prevState)]
}
