import { gql } from '@apollo/client'

export const GET_ADMIN_MENU = gql`
  query getAdminMenu {
    adminMenu {
      ... on Link {
        title
        link
        spaName
      }
      ... on Header {
        title
        links {
          title
          link
          spaName
        }
      }
    }
  }
`
