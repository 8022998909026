import React from 'react'
import cx from 'classnames'
import { array, string, bool, func } from 'prop-types'
import { ChevronRightIcon } from '@toasttab/buffet-pui-icons'
import { useTreeView } from '../useTreeView/useTreeView'
import { getNodeInformation } from '../useTreeView/utils'
import { TreeViewProvider, useTreeViewContext } from './TreeViewContext'
import { object, oneOfType, node, arrayOf, number } from 'prop-types'

export const TreeView = ({
  className = 'ml-4',
  firstDepthClassName = 'ml-0',
  children = BaseNode,
  onClickNode,
  list,
  nodesKey,
  propagateCollapseToNodes,
  isItemInitiallyExpanded,
  testId = 'tree-view'
}) => {
  const { treeView, expand, collapse } = useTreeView({
    list,
    nodesKey,
    propagateCollapseToNodes,
    isItemInitiallyExpanded
  })
  return (
    <div data-testid={testId}>
      <TreeViewProvider
        value={{ nodesKey, className, expand, collapse, onClickNode }}
      >
        <RecursiveList
          list={treeView}
          children={children}
          firstDepthClassName={firstDepthClassName}
          isListExpanded
        />
      </TreeViewProvider>
    </div>
  )
}

const RecursiveList = ({
  list,
  isListExpanded,
  children,
  firstDepthClassName
}) => {
  const { nodesKey, className, expand, collapse } = useTreeViewContext()
  return (
    <ul className={firstDepthClassName || className}>
      {isListExpanded &&
        list.map((node) => {
          const nodeInformation = getNodeInformation(node, nodesKey)
          const { isContainer, isExpanded, id, items } = nodeInformation
          const toggleAction = () => (isExpanded ? collapse : expand)(id)
          return (
            <li key={id}>
              {children({
                node,
                toggle: isContainer ? toggleAction : null,
                ...nodeInformation
              })}
              {isContainer && (
                <RecursiveList list={items} isListExpanded={isExpanded}>
                  {children}
                </RecursiveList>
              )}
            </li>
          )
        })}
    </ul>
  )
}

const baseClassNames = `type-default md:type-subhead whitespace-nowrap
inline-flex flex-row justify-start items-center
h-12 md:h-10 w-full rounded
hover:bg-darken-4 focus:shadow-focus-within
transition duration-200 ease-in-out`
const containerClassNames = `pr-4 pl-2`
const nodeClassNames = `hover:text-link px-4
active:bg-brand-0 active:text-brand-75 active:font-semibold`

export const BaseNode = (props) => {
  const { toggle, children, ...rest } = props
  const { isExpanded, isContainer, id } = rest
  const { onClickNode } = useTreeViewContext()

  const onClick = () => {
    isContainer && toggle && toggle()
    onClickNode && onClickNode(rest)
  }

  const ariaExpanded = isContainer
    ? {
        'aria-expanded': isExpanded
      }
    : {}

  return (
    <button
      data-testid={id}
      onClick={onClick}
      className={cx(
        baseClassNames,
        isContainer ? containerClassNames : nodeClassNames
      )}
      {...ariaExpanded}
    >
      {isContainer && (
        <ChevronRightIcon
          className={cx('transform transition duration-100 ease-in-out', {
            'rotate-90': isExpanded
          })}
        />
      )}
      {children || (isContainer ? `Container: ${id}` : `Node: ${id}`)}
    </button>
  )
}

BaseNode.propTypes = {
  node: object.isRequired,
  toggle: func,
  isExpanded: bool.isRequired,
  isContainer: bool.isRequired,
  items: array,
  id: string.isRequired,
  depth: number.isRequired,
  children: oneOfType([node, arrayOf(node)])
}

RecursiveList.propTypes = {
  list: array.isRequired,
  isListExpanded: bool.isRequired,
  children: func.isRequired,
  firstDepthClassName: string
}

TreeView.propTypes = {
  list: array.isRequired,
  nodesKey: string.isRequired,
  propagateCollapseToNodes: bool,
  isItemInitiallyExpanded: func,
  className: string,
  children: func,
  onClickNode: func
}
