import { useEffect, useLayoutEffect, useState } from 'react'

export const useCssLoader = (name) => {
  const [url, setUrl] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    try {
      setUrl(global.System.resolve(`${name}.css`))
    } catch (e) {
      console.log('no css found')
      setLoading(false)
    }
  }, [name])

  useLayoutEffect(() => {
    if (url) {
      if (document.querySelector('link[href="' + url + '"]')) {
        return
      }
      // create the link element
      const link = document.createElement('link')
      link.type = 'text/css'
      link.rel = 'stylesheet'
      link.setAttribute('data-systemjs-css', true)
      link.href = url
      document.head.appendChild(link)
      link.onerror = (e) => {
        const href = document.querySelector('link[href="' + url + '"]')
        if (href) {
          href.parentElement.removeChild(href)
        }
        setError(e)
        setLoading(false)
      }
      link.onload = (e) => {
        setLoading(false)
      }
      // remove the link when done
      return () => {
        const href = document.querySelector('link[href="' + url + '"]')
        if (href) {
          href.parentElement.removeChild(href)
        }
      }
    }
  }, [url])

  return { error, loading }
}
