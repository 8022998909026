import React from 'react'
import { BanquetLoader as BanquetLoaderRuntime } from 'banquet-runtime-modules'
import { useCssLoader } from '../useCssLoader'

/**
 * @deprecated Replace this version with BanquetLoader
 * from banquet-runtime-modules once all toast admin
 * spas have migrated to banquet-runtime-modules and/or
 * @toasttab/banquet-single-spa-css
 **/
export const BanquetLoader = ({
  name,
  LoadingComponent = () => null,
  ...props
}: {
  name: string
  loadingComponent?: (() => null) | undefined
  [key: string]: any
}) => {
  const { loading: cssLoading, error: cssError } = useCssLoader(name)

  if (cssError) {
    console.error(cssError)
  }

  if (cssLoading) {
    return <LoadingComponent />
  }

  return (
    <BanquetLoaderRuntime
      name={name}
      LoadingComponent={LoadingComponent}
      {...props}
    />
  )
}
