import { useReducer } from 'react'
import cloneDeep from 'lodash.clonedeep'
import { reducer, expand, collapse } from './reducer'
import { getNodeInformation, IS_EXPANDED_KEY, UNIQUE_ID_KEY } from './utils'

/**
 * @callback IsItemInitiallyExpandedFn
 * @param {object} item
 * @returns {boolean}
 */

/**
 * @typedef UseTreeViewProps
 * @property {array} list
 * @property {string} nodesKey
 * @property {boolean?} propagateCollapseToNodes
 * @property {IsItemInitiallyExpandedFn?} isItemInitiallyExpanded
 */

/**
 * @callback TreeViewAction
 * @param {string} uniqueId
 * @returns {void}
 */

/**
 * @typedef BaseTreeViewProperties
 * @property {boolean} _uniqueId
 * @property {boolean} isExpanded
 */

/**
 * @typedef UseTreeViewReturns
 * @property {Array<BaseTreeViewProperties>} treeView
 * @property {TreeViewAction} expand
 * @property {TreeViewAction} collapse
 */

const internalTransformList = ({
  list,
  nodesKey,
  isItemInitiallyExpanded,
  parentIndex = ''
}) => {
  const duplicateList = list.map((item, index) => {
    const newIndex = `${parentIndex}${parentIndex && '-'}${index}`
    item[UNIQUE_ID_KEY] = newIndex

    const nodeInformation = getNodeInformation(item, nodesKey)
    const { isContainer, items } = nodeInformation

    if (isContainer) {
      item[IS_EXPANDED_KEY] =
        isItemInitiallyExpanded(item, nodeInformation) || false
      item[nodesKey] = internalTransformList({
        list: items,
        nodesKey,
        isItemInitiallyExpanded,
        parentIndex: newIndex
      })
    }
    return item
  })
  return duplicateList
}

/**
 * @param {UseTreeViewProps} props
 * @returns
 */
const transformList = ({
  list,
  nodesKey,
  propagateCollapseToNodes,
  isItemInitiallyExpanded
}) => {
  const duplicateList = internalTransformList({
    list,
    nodesKey,
    isItemInitiallyExpanded
  })
  return {
    list: duplicateList,
    nodesKey,
    propagateCollapseToNodes,
    isItemInitiallyExpanded
  }
}

/**
 * useTreeView hook.
 * @param {UseTreeViewProps} props
 * @returns {UseTreeViewReturns}
 */
export const useTreeView = ({
  list,
  nodesKey,
  propagateCollapseToNodes = true,
  isItemInitiallyExpanded = () => false
}) => {
  const [state, dispatch] = useReducer(
    reducer,
    {
      /**
       * Spread operator, Array.from, or any native operation makes a shallow
       * copy (only the top level), anything else is referenced. We have to
       * recursively shallow copy every depth.
       * Or use lodash.clonedeep, as you want.
       */
      list: cloneDeep(list),
      propagateCollapseToNodes,
      nodesKey,
      isItemInitiallyExpanded
    },
    transformList
  )
  return {
    treeView: state.list,
    expand: (id) => dispatch(expand(id)),
    collapse: (id) => dispatch(collapse(id))
  }
}
