import React from 'react'
import cx from 'classnames'
import { useQuery } from '@apollo/client'
import { useLocation } from 'react-router-dom'
import {
  MerryGoRound,
  SIZE as LoadingSize
} from '@toasttab/buffet-pui-loading-indicators'
import { TreeView } from '@toasttab/buffet-pui-tree-view'
import { Alert } from '@toasttab/buffet-pui-alerts'
import { Modal } from '@toasttab/buffet-pui-modal'
import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'
import { GET_ADMIN_MENU } from '../../api/queries/adminMenu'
import { getModalParentElement } from '../../utils/modalAppElement'
import { useNavigationVisibilityContext } from './NavigationVisibilityContext'
import { Node } from './Node'
import { getNodeFromPath } from '../../utils/getNodeFromPath'
import { DEFAULT_ADMIN_MENU_RESPONSE } from '../../api/constants'

const Base = ({ children }) => (
  <div
    className={cx(
      `w-full md:w-52 md:h-full md:border-r md:overflow-y-auto bg-white
      pt-4 md:py-6 md:px-2`
    )}
  >
    {children}
  </div>
)

const baseListClassName = 'overflow-x-auto'

export const InternalNavigation = ({ list }) => {
  const { pathname } = useLocation()
  const activeNode = getNodeFromPath(pathname, list)

  const isItemInitiallyExpanded = (item) =>
    item?.links?.some((node) => node.link === activeNode.link)

  return (
    <Base>
      <nav>
        <TreeView
          list={list}
          nodesKey='links'
          propagateCollapseToNodes
          testId='navigation'
          className={cx('pl-4', baseListClassName)}
          firstDepthClassName={cx('pl-0', baseListClassName)}
          isItemInitiallyExpanded={isItemInitiallyExpanded}
        >
          {Node}
        </TreeView>
      </nav>
    </Base>
  )
}

const NavigationState = ({ loading, error, data }) => {
  if (loading) {
    return (
      <Base>
        <div className='flex flex-col justify-center items-center'>
          <MerryGoRound testId='loading-navigation' size={LoadingSize.md} />
        </div>
      </Base>
    )
  }

  if (error) {
    return (
      <Base>
        <Alert variant='error' showIcon testId='error-navigation'>
          An error occurred while retrieving navigation links:{' '}
          {error?.message || 'Unknown error'}
        </Alert>
      </Base>
    )
  }

  if (data) {
    return <InternalNavigation list={data.adminMenu} />
  }
}

export const Navigation = () => {
  // TODO: Replace Default with BFF query once new navigation service is available
  //    const queryState = useQuery(GET_ADMIN_MENU)
  const queryState = DEFAULT_ADMIN_MENU_RESPONSE
  const [isVisible, toggleVisibility] = useNavigationVisibilityContext()
  const screenSize = useScreenSize()

  if (screenSize < ScreenSize.MD) {
    return (
      <Modal
        isOpen={isVisible}
        onRequestClose={() => toggleVisibility()}
        parentSelector={getModalParentElement}
        noFooter
      >
        <Modal.Header>Administration Links</Modal.Header>
        <Modal.Body>
          <NavigationState {...queryState} />
        </Modal.Body>
      </Modal>
    )
  }

  return <NavigationState {...queryState} />
}
