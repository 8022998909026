import React, { useState } from 'react'
import { BrowserRouter } from 'react-router-dom'
import {
  ApolloClient,
  ApolloProvider,
  ApolloLink,
  InMemoryCache,
  HttpLink
} from '@apollo/client'
import { BFF_BASE_URI } from '../../api/constants'
import { NavigationVisibilityProvider } from '../Navigation/NavigationVisibilityContext'
import { App } from './App'

const link = ApolloLink.from([new HttpLink({ uri: BFF_BASE_URI })])
const apolloClient = new ApolloClient({
  name: 'spa-toast-administration',
  link: link,
  cache: new InMemoryCache()
})

export const AppSetup = () => {
  /**
   * Desktop Navigation won't use the variable, only on mobile when displayed
   * as a modal. By default, we want the Navigation component to be hidden on
   * mobile.
   */
  const state = useState(false)
  return (
    <ApolloProvider client={apolloClient}>
      <BrowserRouter basename='/'>
        <NavigationVisibilityProvider value={state}>
          <App />
        </NavigationVisibilityProvider>
      </BrowserRouter>
    </ApolloProvider>
  )
}
