import React from 'react'
import { BanquetLoader } from '@toasttab/banquet-loader'
import { useBanquetProps } from 'banquet-runtime-modules'
import { useNavigationVisibilityContext } from '../Navigation'
import { UserAccountActions } from '@toasttab/buffet-pui-user-account-actions'
import { ResponsiveBranding } from '@toasttab/buffet-pui-responsive-branding'
import { IconButton } from '@toasttab/buffet-pui-buttons'
import { MenuIcon } from '@toasttab/buffet-pui-icons'

export const Header = () => {
  const { auth, restaurantInfo } = useBanquetProps()

  const [, toggleVisiblity] = useNavigationVisibilityContext()

  return (
    <div
      className='md:col-span-2 md:h-16 md:z-30 w-screen'
      data-testid='header'
    >
      <div
        data-testid='left'
        className='bg-white w-full flex flex-row justify-start items-center relative h-full'
      >
        <ResponsiveBranding />

        <div className='pl-2 md:pl-4 flex items-center flex-grow'>
          <IconButton
            className='visible md:hidden'
            onClick={() => toggleVisiblity()}
            textClassName='text-secondary hover:text-default'
            icon={<MenuIcon />}
            title='Show navigation menu'
            testId='show-navigation'
          />
          <div className='w-2' />
          <h2
            data-testid='title'
            className='type-default font-semibold text-default'
          >
            Admin Portal
          </h2>
          <div className='flex-grow' />
          <div
            data-testid='right'
            className='flex flex-row justify-end items-center pr-4'
          >
            <BanquetLoader
              name='spa-customer-sessions'
              auth={auth}
              restaurantInfo={restaurantInfo}
              data-testid='indicator'
              className='mr-4'
            />
            <UserAccountActions />
          </div>
        </div>
        <div className='border-b absolute bottom-0 left-0 w-full h-px'></div>
      </div>
    </div>
  )
}
