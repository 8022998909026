/**
 * @typedef AdminMenuItem
 * @property {string} title
 * @property {string?} link
 * @property {string?} spaName
 * @property {Array<AdminMenuItem>?} links
 */

/**
 * Returns node from admin menu based on path. If not found, will return
 * the home page (Restaurant Access).
 * @param {string} path The pathname section from an URL.
 * @param {Array<AdminMenuItem>} menu The array of admin links.
 * @returns {AdminMenuItem?}
 */
export const getNodeFromPath = (path, menu) => {
  const mutableMenu = [...menu]
  const home = mutableMenu.shift()
  return internalGetNodeFromPath(path, mutableMenu) ?? home
}

/**
 * Returns node from admin menu based on path. If not found, will return
 * undefined.
 * @param {string} path The pathname section from an URL.
 * @param {Array<AdminMenuItem>} menu The array of admin links.
 * @returns {AdminMenuItem?}
 */
const internalGetNodeFromPath = (path, menu) => {
  return menu.reduce((acc, node) => {
    if (node?.links) {
      const foundNode = internalGetNodeFromPath(path, node.links)
      if (!foundNode) return acc
      if (!acc) return foundNode
      return returnItemWithLongerLink(acc, foundNode)
    }
    if (path.startsWith(node?.link)) {
      if (!acc) return node
      return returnItemWithLongerLink(acc, node)
    }
    return acc
  }, undefined)
}
/**
 * Returns node based on link length. Will return the first if the length is
 * identical. If one of them is absent, it will return the other. If they are
 * both absent, it will return undefined.
 * @param {AdminMenuItem?} first This first item.
 * @param {AdminMenuItem?} second The second item.
 * @returns {AdminMenuItem?}
 */
const returnItemWithLongerLink = (first, second) => {
  if (!first) return second
  if (!second) return first
  return first.link.length >= second.link.length ? first : second
}
