import React, { forwardRef } from 'react'
import { func, string } from 'prop-types'
import { ToastAccountIcon } from '@toasttab/buffet-pui-icons'
import { IconButton } from '@toasttab/buffet-pui-buttons'

/**
 * UserName is a wrapper on top of Button, so it is essentially a button.
 * Therefore we need to provide tools to other component to use it as a button.
 */
export const UserNameButton = forwardRef(
  ({ onClick, className, testId = 'user-name-cta' }, ref) => {
    return (
      <IconButton
        ref={ref}
        size='sm'
        className={className}
        textClassName='text-secondary hover:text-default focus:text-default'
        icon={<ToastAccountIcon />}
        onClick={onClick}
        title='Click to see available actions for you account'
        testId={testId}
      />
    )
  }
)

UserNameButton.propTypes = {
  onClick: func,
  className: string,
  testId: string
}
