import React from 'react'
import cx from 'classnames'
import { useLocation } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { useQuery } from '@apollo/client'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'
import { BanquetLoader } from '@toasttab/banquet-loader'
import { useBanquetProps } from 'banquet-runtime-modules'
import { GET_ADMIN_MENU } from '../../api/queries/adminMenu'
import { getNodeFromPath } from '../../utils/getNodeFromPath'
import { ScreenSize, useScreenSize } from '@toasttab/use-screen-size'
import { DEFAULT_ADMIN_MENU_RESPONSE } from '../../api/constants'

export const Content = () => {
  const screenSize = useScreenSize()
  const { auth } = useBanquetProps()
  // TODO: Remove this call when all spas that expect history have been updated
  const history = createBrowserHistory({ basename: '/toast/admin' })

  const { pathname } = useLocation()
  // TODO: Replace Default with BFF query once new navigation service is available
  //   const { data = [] } = useQuery(GET_ADMIN_MENU)
  const data = DEFAULT_ADMIN_MENU_RESPONSE.data
  const node = getNodeFromPath(pathname, data?.adminMenu || [])

  const isDesktop = screenSize > ScreenSize.SM

  return (
    <div
      data-testid='content'
      className={cx('relative', { 'overflow-auto': isDesktop })}
      style={{
        height: isDesktop ? 'calc(100vh - 64px)' : undefined,
        width: isDesktop ? 'calc(100vw - 13rem)' : undefined
      }}
    >
      <div data-internal className='h-full'>
        {node?.spaName && (
          <BanquetLoader
            name={node?.spaName}
            className='h-full'
            history={history}
            // TODO: Pass down auth once available
            //             auth={auth}
            LoadingComponent={() => (
              <MerryGoRound
                className='pin-center'
                testId='content-loading'
                size='md'
              />
            )}
          />
        )}
      </div>
    </div>
  )
}
